'use client';
import { useContext, useEffect } from 'react';

import { InventoryContext } from '@/contexts/InventoryContext';
import { getSelectionChannel } from '@/tools/broadcast';
import { ItemsReducerActionType } from '@lamesarv-sdk/types';

export const InventoryHandler = () => {
  const { dispatch } = useContext(InventoryContext);

  const fetchSelection = () => {
    const inventorySelectedData = localStorage.getItem('inventorySelected');

    if (inventorySelectedData) {
      const inventorySelected = JSON.parse(inventorySelectedData);

      dispatch({
        type: ItemsReducerActionType.updateSelected,
        payload: inventorySelected,
      });
    }
  };

  const fetchFavorites = () => {
    const inventoryFavoritesData = localStorage.getItem('inventoryFavorites');

    if (inventoryFavoritesData) {
      const inventoryFavorites = JSON.parse(inventoryFavoritesData);

      dispatch({
        type: ItemsReducerActionType.updateFavorites,
        payload: inventoryFavorites,
      });
    }
  };

  useEffect(() => {
    const channelSelection = getSelectionChannel();

    fetchSelection();
    fetchFavorites();

    return () => {
      channelSelection.close();
    };
  }, []);

  return null;
};
