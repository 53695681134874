import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { getInventoryImage } from '@lamesarv-sdk/tools';
import { defaultImageUrl } from '@/constants/inventory';
import { ISuggestion } from '@lamesarv-sdk/components';

import { IBasicInventory, InventoryField } from '@lamesarv-sdk/types';

export interface ItemSuggestionProps {
  suggestion: ISuggestion & IBasicInventory;
  query: string;
}

export const AutoCompleteInventoryItem = ({ suggestion, query }: ItemSuggestionProps) => {
  const matches = match(suggestion._title ?? '', query);
  const parts = parse(suggestion._title ?? '', matches);

  return (
    <div className="flex flex-row">
      <img className="w-20 h-auto" src={getInventoryImage({ item: suggestion, defaultImageUrl })} alt="" />
      <div className="pl-2 leading-none">
        <div className="font-semibold text-sm mb-1">
          <div>
            {parts.map((part) =>
              part.highlight ? (
                <span key={part.text} className="bg-brown-400">
                  {part.text}
                </span>
              ) : (
                part.text
              ),
            )}
          </div>
          <div className="uppercase text-xs text-sage-500">{suggestion[InventoryField.stockNumber]}</div>
        </div>
        <div className="text-xs">
          {suggestion[InventoryField.body]} - {suggestion[InventoryField.fuelType]}
        </div>
        <div className="flex flex-row text-xs h-4">
          <FontAwesomeIcon icon={faLocationDot} className="flex w-4 mr-1" /> {suggestion[InventoryField.location]}
        </div>
      </div>
    </div>
  );
};
