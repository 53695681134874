import { ISuggestion } from '@lamesarv-sdk/components';

import { IBlogPostData } from '@lamesarv-sdk/types';

export interface ItemSuggestionProps {
  suggestion: ISuggestion & IBlogPostData;
  query: string;
}

export const AutoCompleteBlogItem = ({ suggestion }: ItemSuggestionProps) => {
  return (
    <div className="flex flex-col">
      <div className="text-sm font-semibold">{suggestion._title}</div>
      <div className="text-sm text-gray-600">{suggestion._description}</div>
    </div>
  );
};
