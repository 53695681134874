import { useState, useEffect } from 'react';

import { IBlogPostData, IGetItems, ISearchResults } from '@lamesarv-sdk/types';
import { IGetBlogPostsOutput } from '@lamesarv-sdk/libs/dist/cms/parsers';

export const useGetBlogItems = (props: IGetItems) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [items, setItems] = useState<ISearchResults<IBlogPostData>>({
    hits: [],
    nbHits: 0,
    nbPages: 0,
  });

  const resetItems = () => {
    setItems({
      hits: [],
      nbHits: 0,
      nbPages: 0,
    });
    setLoading(false);
    setError(false);
  };

  const fetchItems = async (fetchProps?: IGetItems): Promise<ISearchResults<IBlogPostData>> => {
    const _props = fetchProps ?? props;

    try {
      setLoading(true);
      setError(false);

      const response = await fetch('/api/blog/posts', {
        method: 'POST',
        body: JSON.stringify(_props),
      });
      const responseData: IGetBlogPostsOutput = await response.json();

      const { data, total } = responseData;

      const blogData = {
        hits: data.map((item) => ({ document: item })),
        nbHits: total ?? 0,
        nbPages: Math.ceil((total ?? 0) / (_props?.perPage ?? 1)),
      };

      setItems(blogData);

      if (_props.callback) {
        _props.callback(data);
      }

      setLoading(false);

      return blogData;
    } catch (err) {
      setLoading(false);
      setError(true);
      return {
        hits: [],
        nbHits: 0,
        nbPages: 0,
      };
    }
  };

  useEffect(() => {
    if (!props.skipFetch) {
      fetchItems();
    }
  }, []);

  return {
    loading,
    error,
    items,
    fetchItems,
    resetItems,
  };
};
