'use client';
import { Fragment, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { Form } from '@/components/Form/Form';
import { FormType } from '@/types/forms';
import { ILayoutFooter } from '@/types/layout';
import { LinksGroup, LinksList, LinksSocial } from '@lamesarv-sdk/components';
import { ILink } from '@lamesarv-sdk/types';

const FooterSection = (props: { title: string; className?: string } & PropsWithChildren<unknown>) => {
  return (
    <div className={twMerge('flex flex-col md:w-1/5', props.className)}>
      <div className="text-sm font-bold text-white mb-1">{props.title}</div>
      {props.children}
    </div>
  );
};

const FooterSectionLinks = (props: { title: string; links: ILink[]; className?: string }) => {
  return (
    <FooterSection title={props.title} className={props.className}>
      <LinksGroup
        links={props.links}
        className="mb-0"
        classList="leading-8 p-0"
        classListItem="text-sm text-neutral-400 hover:text-neutral-500 hover:decoration-neutral-600 hover:underline hover:underline-offset-4"
      />
    </FooterSection>
  );
};

export const LayoutFooter = (props: ILayoutFooter) => {
  return (
    <Fragment>
      <div className="bg-neutral-700 py-8 px-4 md:px-6">
        <div className="container mx-auto px-4 md:px-0">
          <div className="text-sm text-white font-bold uppercase mb-2">
            {props.emailOfferTitle || 'STAY INFORMED ON NEW ARRIVALS AND GET EXCLUSIVE ACCESS TO OUR BEST DEALS!'}
          </div>
          <Form formType={FormType.newsletterFooterNew} className="" />
        </div>
      </div>
      <div className="bg-neutral-900 py-8 px-4 md:px-6">
        <div className="container mx-auto px-4 md:px-0">
          <div className="flex flex-col md:flex-row gap-9 md:gap-0">
            <FooterSectionLinks {...props.rvShoppingLinks} />
            <FooterSectionLinks {...props.rvResourcesLinks} />
            <FooterSectionLinks {...props.aboutLinks} />
            <div className="flex flex-col md:w-1/5">
              {props.locationLinks?.slice(0, 2)?.map((linkGroup, index) => {
                return <FooterSectionLinks key={index} {...linkGroup} className="md:w-full mb-4 last:mb-0" />;
              })}
            </div>
            <div className="flex flex-col md:w-1/5">
              {props.locationLinks?.slice(2, 4)?.map((linkGroup, index) => {
                return <FooterSectionLinks key={index} {...linkGroup} className="md:w-full mb-4 last:mb-0" />;
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-neutral-900 py-8 border-t border-neutral-600 px-4 md:px-6">
        <div className="container mx-auto px-4 md:px-0">
          <div className="flex flex-col md:flex-row gap-10 md:gap-0">
            <div className="md:basis-3/4 text-xs text-neutral-500 order-2">{props.copyright}</div>
            <div className="md:basis-1/4 flex flex-col gap-2 md:items-end order-1 md:order-3">
              <div className="text-sm text-white tex font-bold uppercase">Get Connected</div>
              <LinksSocial
                links={props.socialLinks.links}
                classList="mt-0 gap-2"
                classListWrapper="m-0"
                classIcon="border-2 border-white p-2 rounded-full text-white"
              />
            </div>
          </div>
          <div className="flex flex-col-reverse gap-2 md:gap-0 md:flex-row items-center justify-center mt-10 text-xs text-neutral-300 mb-2">
            <div>
              <span>Copyright © {new Date().getFullYear()} La Mesa RV Center, Inc.</span>
            </div>
            <LinksList
              links={props.footerLinks}
              classListWrapper="mx-1 pl-2"
              classList="ml-1 flex-wrap gap-2 sm:divide-x divide-neutral-500"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
