'use client';
import { usePathname } from 'next/navigation';

export const PreviewFlag = () => {
  const pathname = usePathname();

  return (
    <div className="bg-blue-500 sticky top-0 z-50 flex justify-center items-center py-2 text-white font-bold text-lg">
      <span>Preview mode.</span>

      <a href={`/api/page/preview/clear?path=${encodeURIComponent(pathname)}`} className="ml-2 underline">
        Disable it.
      </a>
    </div>
  );
};
